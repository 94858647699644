import React, { useEffect } from "react";
import { Formik } from "formik";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import InputField from "../elements/inputs/InputField"; // Assuming you have this component
import {
  usePostAddNotificationMutation,
  usePostAllUsersDataMutation,
} from "../../services/apiService"; // New API hook
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup"; // For validation schema
import Select, { components } from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react"; // Importing CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; // Importing ClassicEditor build
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { setUserList } from "../../redux/slice/userSlice";

const MySwal = withReactContent(Swal);

const options = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
    "&:focus": {
      // border: "none",
    },
    "&:active": {
      // border: "none",
    },
    "&:hover": {
      // border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "100px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

// Validation schema for the form fields
const notificationSchema = Yup.object().shape({
  // heading: Yup.string().required("Required"),
  // content: Yup.string().required('Required'),
  send_mail: Yup.string().required("Required"),
  send_sms: Yup.string().required("Required"),
  send_push: Yup.string().required("Required"),
});

const AddNotificationModal = ({
  closeAddNotificationModal,
  onNotificationAdded,
}) => {
  const dispatch = useDispatch();
  const [postAddNotification, { isLoading }] = usePostAddNotificationMutation();
  const [
    postAllUsersData,
    { isLoading: isUserLoading, isFetching: isUserFetching },
  ] = usePostAllUsersDataMutation();
  const { userList } = useSelector((state) => state.user);

  const fetchUsers = async () => {
    const payload = { page: 1, page_size: 1, search: "", type: "all" };
    const response = await postAllUsersData({ query: "", payload: payload });

    if (response?.data) {
      dispatch(setUserList(response.data));
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);

  console.log("userData", userData);

  const handleSubmitNotification = (values, resetForm) => {
    const payload = {
      notifiable_id: JSON.stringify(
        values.notifiable_id?.map((el) => {
          return el?.id;
        })
      ),
      data: JSON.stringify({
        name: "",
        link: values?.link,
        push: values.send_push ? 1 : 0,
        sms: values.send_sms ? 1 : 0,
        mail: values.send_mail ? 1 : 0,
        db: 1,
        remark: null,
        bc: 1,
        subject: values.subject,
        content: values.content,
      }),
      type: JSON.stringify({
        push: values.send_push ? 1 : 0,
        sms: values.send_sms ? 1 : 0,
        mail: values.send_mail ? 1 : 0,
      }),
      notifiable_type: "organization", // Hardcoded value
      org_id: userData?.user.org_id,
    };

    postAddNotification({ query: "", payload: payload })
      .then((res) => {
        if (res?.data) {
          MySwal.fire({
            title: "Success",
            text: "Notification sent successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            resetForm();
            onNotificationAdded();
            closeAddNotificationModal(); // Close the modal after success
          });
        }
      })
      .catch((err) => {
        console.error("Error sending notification:", err);
      });
  };

  return (
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Send Notification</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              closeAddNotificationModal();
            }}
          />
        </div>
        <div className="modal-body right-side">
          <Formik
            initialValues={{
              subject: "",
              content: "",
              send_mail: "0",
              send_sms: "0",
              send_push: "0",
              group_type: "",
              link: "",
              keywords: "",
              notifiable_id: [],
            }}
            onSubmit={(values, { resetForm }) =>
              handleSubmitNotification(values, resetForm)
            }
            validationSchema={notificationSchema}
            enableReinitialize
            validateOnChange={false}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* {console.log("values", values)} */}
                <div className="row mb-0">
                  <small className="text-danger pb-2">
                    Note: In text notification, the user will receive only the
                    heading and link section.
                  </small>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa fa-bell" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={
                            "did-floating-label did-floating-labe-astrisk"
                          }
                          type="text"
                          name="subject"
                          label={"Subject"}
                          value={values.subject}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors?.subject && touched?.subject ? (
                        <div className="error-message">{errors?.subject}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <div className=" input-group">
                        <span className="input-group-text">
                          <i className="fa fa-link" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={
                            "did-floating-label did-floating-labe-astrisk"
                          }
                          type="text"
                          name="link"
                          label={"Link"}
                          value={values.link}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.link && touched.link ? (
                        <div className="error-message">{errors.link}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-lg-4 col-md-4 mb-3">
                    <Select
                      onChange={(e) => setFieldValue("send_sms", e.value)}
                      placeholder={""}
                      label={"Send SMS?"}
                      value={
                        options.filter(
                          ({ value }) => values?.send_sms == value
                        ) || ""
                      }
                      styles={colourStyles}
                      options={options}
                      components={{ Control }}
                      isRequired={true}
                    />

                    {errors.send_sms && touched.send_mail ? (
                      <div className="error-message">{errors.send_sms}</div>
                    ) : null}
                  </div>
                  <div className="col-lg-4 col-md-4 mb-3">
                    <Select
                      onChange={(e) => setFieldValue("send_mail", e.value)}
                      placeholder={""}
                      label={"Send Mail?"}
                      value={
                        options.filter(
                          ({ value }) => values?.send_mail == value
                        ) || ""
                      }
                      styles={colourStyles}
                      options={options}
                      components={{ Control }}
                      isRequired={true}
                    />

                    {errors.send_mail && touched.send_mail ? (
                      <div className="error-message">{errors.send_mail}</div>
                    ) : null}
                  </div>
                  <div className="col-lg-4 col-md-4 mb-3">
                    <Select
                      onChange={(e) => setFieldValue("send_push", e.value)}
                      placeholder={""}
                      label={"Push Notification?"}
                      value={
                        options.filter(
                          ({ value }) => values?.send_push == value
                        ) || ""
                      }
                      styles={colourStyles}
                      options={options}
                      components={{ Control }}
                      isRequired={true}
                    />

                    {errors.send_push && touched.send_push ? (
                      <div className="error-message">{errors.send_push}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className={"col-md-12 mb-3"}>
                    <Select
                      getOptionLabel={(option) =>
                        `${
                          option?.f_name +
                          " " +
                          option?.m_name +
                          " " +
                          option?.l_name
                        }`
                      }
                      getOptionValue={(option) => option.id}
                      isMulti
                      closeMenuOnSelect={false}
                      options={userList}
                      styles={colourStyles}
                      placeholder=""
                      isRequired={true}
                      value={values.notifiable_id}
                      label={"Select Users"}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: "notifiable_id",
                            value: selectedOption,
                          },
                        });
                      }}
                      components={{
                        Control,
                        MultiValue,
                        // DropdownIndicator: () => null,
                        // IndicatorSeparator: () => null,
                      }}
                    />
                    {errors.notifiable_id && touched.notifiable_id ? (
                      <div className="error-message">
                        {errors.notifiable_id}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-sm-12 mb-3">
                  <label className="pb-2 label">
                    Content<span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    placeholder="Type something"
                    data={values?.content}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFieldValue("content", data);
                    }}
                  />
                  {errors.content && touched.content ? (
                    <div className="error-message">{errors.content}</div>
                  ) : null}
                </div>
                <div className="pt-4 d-flex justify-content-center pb-3">
                  <button className="btn btn-primary w-25 p-2" type="submit">
                    {"Submit"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};
const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
export default AddNotificationModal;
