import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  usePostAllNotificationDataMutation,
  useReadNotificationMutation,
} from "../../services/apiService";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import moment from "moment";
import { limitString } from "../../utils/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationList,
  setUnreadCount,
} from "../../redux/slice/notificationSlice";

const DashHeader = () => {
  const navigate = useNavigate();
  const [postAllNotificationData, { isLoading, isFetching }] =
    usePostAllNotificationDataMutation();
  const [readNotification] = useReadNotificationMutation();
  const dispatch = useDispatch();
  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);

  const { notificationList, unreadCount } = useSelector(
    (state) => state.notification
  );

  const fetchAndSetNotificationList = async (id) => {
    const payload = { id: id, page: 1, page_size: 5, search: "" };
    const response = await postAllNotificationData({
      query: "",
      payload: payload,
    });

    if (response?.data) {
      dispatch(setNotificationList(response.data));
      dispatch(setUnreadCount(response?.data));
    }
  };

  const goTo = (path) => {
    navigate(path);
  };

  const handleReadNotification = async (notificationId) => {
    try {
      await readNotification(notificationId);

      // Re-fetch the notifications after marking the notification as read
      fetchAndSetNotificationList(userData.user.id);
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
      toast.error("Failed to mark notification as read");
    }
  };

  useEffect(() => {
    if (userData?.user?.id) {
      fetchAndSetNotificationList(userData.user.id);
    }
  }, [userData?.user?.id]);

  return (
    <>
      <div className="add-position">
        <header className="main-header login-header-home remove-position">
          <nav className="navbar navbar-expand-lg sticky-top">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/admin/home">
                <img
                  src="/assets/images/newLogo.png"
                  className="img-fluid"
                  alt="logo"
                />
              </Link>

              <div className="d-flex align-items-center gap-2">
                {/* Notifications Dropdown */}
                <div className="dropdown nav-item dropdown-header remove-drop-icon notification-dropdown p-0">
                  <button
                    className="nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-bell" aria-hidden="true"></i>
                    {unreadCount && (
                      <span className="badge bg-danger">{unreadCount}</span>
                    )}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    {notificationList?.length === 0 ? (
                      <li>
                        <span className="dropdown-item">No notifications</span>
                      </li>
                    ) : (
                      notificationList?.map((notification, index) => (
                        <li
                          key={index}
                          style={{
                            backgroundColor: !notification.read_at
                              ? "#eef4f7" // Unread notification highlight (light gray)
                              : "transparent",
                          }}
                        >
                          <Link
                            className={`dropdown-item ${
                              notification.read_at ? "text-muted" : ""
                            }`}
                            // to={`/notification/${notification.id}`}
                            onClick={() =>
                              !notification.read_at &&
                              handleReadNotification(notification.id)
                            }
                          >
                            <h6 className="preview-subject font-weight-normal mb-1 pe-3">
                              {JSON.parse(notification?.data)?.subject}
                            </h6>
                            <p
                              className="text-gray ellipsis mb-0"
                              dangerouslySetInnerHTML={{
                                __html: limitString(
                                  JSON.parse(notification?.data)?.content,
                                  40
                                ),
                              }}
                            ></p>
                            {!notification.read_at && (
                              <small className="text-muted">(Read)</small>
                            )}
                            <p className="time-span">
                              {moment(notification?.created_at).fromNow()}
                            </p>
                          </Link>
                        </li>
                      ))
                    )}
                    <div className="dropdown-divider"></div>
                    <h6
                      className="p-3 mb-0 text-center"
                      onClick={() => {
                        goTo("/admin/notifications-list");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      See all notifications
                    </h6>
                  </ul>
                </div>

                {/* Profile Dropdown */}
                <div className="dropdown nav-item dropdown-header p-0">
                  <button
                    className="nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/images/avatar.png"
                      alt="..."
                      className="img-fluid rounded-circle"
                    />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <NavLink
                        exact={true}
                        activeClassName="active"
                        className="dropdown-item"
                        to="/admin/profile"
                      >
                        Organization
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact={true}
                        activeClassName="active"
                        className="dropdown-item"
                        to="/admin/change-password"
                      >
                        Change Password
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact={true}
                        to={"/login"}
                        activeClassName="active"
                        className="dropdown-item"
                        onClick={() => {
                          localStorage.clear();
                        }}
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default React.memo(DashHeader);
