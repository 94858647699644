import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import InputField from "../../../components/elements/inputs/InputField";
import CryptoJS from "crypto-js";
import Select, { components } from "react-select";
import {
  usePostEmailSmsDetailsMutation,
  useGetEmailSmsSettingsMutation,
  useGetEmailProvidersQuery,
} from "../../../services/apiService";
import Spinner from "../../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.26rem 0.75rem",
    "&:focus": {
      // border: "none",
    },
    "&:active": {
      // border: "none",
    },
    "&:hover": {
      // border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "100px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Pending", value: "pending" },
];

const EmailDetails = () => {
  const { data: providerOptions } = useGetEmailProvidersQuery();

  const [initialValues, setInitialValues] = useState({
    id: "",
    sms_provider_name: "",
    account_sid: "",
    auth_token: "",
    api_key: "",
    api_secret: "",
    status: "active",
  });
  const encryptedOrg = localStorage.getItem("org_data");
  const decryptedOrg = CryptoJS.AES.decrypt(
    encryptedOrg,
    process.env.REACT_APP_CRYPTOJS_SECRETKEY,
    {
      iv: process.env.REACT_APP_CRYPTOJS_VECTOR,
    }
  ).toString(CryptoJS.enc.Utf8);
  const orgData = decryptedOrg && JSON.parse(decryptedOrg);

  const [getEmailSmsSettings, { data: settingsData, isLoading: isFetching }] =
    useGetEmailSmsSettingsMutation();

  const [postEmailSmsDetails, { isLoading: isPosting }] =
    usePostEmailSmsDetailsMutation();

  const fetchAndUpdateSettings = async (orgId) => {
    try {
      const response = await getEmailSmsSettings(orgId);
      if (response?.data) {
        const prevData =
          response?.data?.notification_details &&
          response?.data?.notification_details?.find((notification) => {
            return notification?.notification_type === "email";
          });
        setInitialValues({
          ...prevData,
          sms_provider_name: providerOptions?.cloud_storage_providers?.find(
            (option) => option.id === prevData?.sms_provider_name
          ),
        });
      }
    } catch (error) {
      console.error("Failed to fetch settings:", error);
      toast.error("Failed to fetch settings");
    }
  };

  useEffect(() => {
    fetchAndUpdateSettings(orgData?.master_org?.org_id);
  }, [getEmailSmsSettings, orgData?.master_org?.org_id]);

  useEffect(() => {
    const handleEmailTabSelected = () => {
      fetchAndUpdateSettings(orgData?.master_org?.org_id);
    };

    window.addEventListener("emailTabSelected", handleEmailTabSelected);

    return () => {
      window.removeEventListener("emailTabSelected", handleEmailTabSelected);
    };
  }, [fetchAndUpdateSettings, orgData?.master_org?.org_id]);

  const handleProviderChange = (selectedOption, setFieldValue) => {
    setFieldValue("sms_provider_name", selectedOption);
  };

  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      id: values?.id || "",
      org_id: orgData?.master_org?.org_id,
      sms_provider_name: values?.sms_provider_name?.id,
      notification_type: "email",
      account_sid: values?.account_sid,
      auth_token: values?.auth_token,
      api_key: values?.api_key,
      api_secret: values?.api_secret,
      status: values?.status,
    };

    postEmailSmsDetails({ query: "", payload: payload }).then((res) => {
      if (res?.data) {
        MySwal.fire({
          title: "Success",
          text: "Settings Updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        resetForm();
        fetchAndUpdateSettings(orgData?.master_org?.org_id);
      } else {
        toast.error("Failed to update settings");
      }
    });
  };

  const handleStatusChange = (option, setFieldValue) => {
    setFieldValue("status", option.value);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-9 col-md-12">
          <div className="right-side">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              // validationSchema={BasicDetailSchemaForAdminAndUser}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h4 className="login-heading mb-3">Email & SMS Settings</h4>
                  <div className="row mb-0">
                    <div className="col-md-12 mb-3">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={statusOptions}
                        styles={colourStyles}
                        placeholder=""
                        label={"Select Status"}
                        value={statusOptions.find(
                          (option) => option?.value === values?.status
                        )}
                        onChange={(option) =>
                          handleStatusChange(option, setFieldValue)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors?.status && touched?.status ? (
                        <div className="error-message">{errors?.status}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3">
                      <Select
                        getOptionLabel={(option) => `${option.provider_name}`}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={providerOptions?.email_providers}
                        styles={colourStyles}
                        placeholder=""
                        isRequired={true}
                        value={providerOptions?.email_providers?.find(
                          (option) =>
                            option.id === values?.sms_provider_name?.id
                        )}
                        label={"Select Email Provider"}
                        onChange={(option) =>
                          handleProviderChange(option, setFieldValue)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors.sms_provider_name && touched.sms_provider_name ? (
                        <div className="error-message">
                          {errors.sms_provider_name}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa fa-id-card" aria-hidden="true" />
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="account_sid"
                              label={"Account SID"}
                              value={values?.account_sid}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors?.account_sid && touched?.account_sid && (
                              <div className="error-message">
                                {errors?.account_sid}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa fa-lock" aria-hidden="true" />
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={"did-floating-label"}
                              type="text"
                              name="auth_token"
                              label={"Auth Token"}
                              value={values?.auth_token}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors?.auth_token && touched?.auth_token && (
                              <div className="error-message">
                                {errors?.auth_token}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa fa-key" aria-hidden="true" />
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="api_key"
                              label={"API Key"}
                              value={values?.api_key}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors?.api_key && touched?.api_key && (
                              <div className="error-message">
                                {errors?.api_key}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa fa-key" aria-hidden="true" />
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={"did-floating-label"}
                              type="text"
                              name="api_secret"
                              label={"API Secret"}
                              value={values?.api_secret}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors?.api_secret && touched?.api_secret && (
                              <div className="error-message">
                                {errors?.api_secret}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="common-btn w-100">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Spinner isLoading={isFetching || isPosting} />
    </div>
  );
};

export default EmailDetails;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
