import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationList,
  setPage,
  setPageSize,
  setSearch,
  setSentNotificationsList,
} from "../../redux/slice/notificationSlice";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import {
  usePostSentNotificationDataMutation,
  useReadNotificationMutation,
} from "../../services/apiService";

import Spinner from "../../components/elements/Spinner/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Breadcrumb from "../../components/elements/Breadcrumb/Breadcrumb";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import { toast } from "react-toastify";
import moment from "moment";
import AdminLayout from "../../components/Admin/AdminLayout";
import AddNotificationByAdminModal from "../../components/AddNotificationByAdminModal/AddNotificationByAdminModal";
import { limitString } from "../../utils/commonFunction";
const MySwal = withReactContent(Swal);
const $ = window.$;

const AdminNotificationManagement = () => {
  const breadcrumbItems = [{ label: "Notifications" }];

  const {
    sentNotificationsList,
    page,
    page_size,
    search,
    total_pages,
    totalRecords,
    from,
    to,
  } = useSelector((state) => state.notification);

  const dispatch = useDispatch();
  const [postSentNotificationData, { isLoading, isFetching }] =
    usePostSentNotificationDataMutation();

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);

  const [paginationLength, setPaginationLength] = useState(page_size);
  const [showAddNotificationModal, setShowAddNotificationModal] =
    useState(false);

  const openAddNotificationModal = () => {
    setShowAddNotificationModal(true);
  };

  const closeAddNotificationModal = () => {
    setShowAddNotificationModal(false);
  };

  const fetchNotifications = async (id) => {
    const payload = { id: id, page, page_size, search };
    const response = await postSentNotificationData({
      query: "",
      payload: payload,
    });

    if (response?.data) {
      console.log("response?.data", response?.data);
      dispatch(setSentNotificationsList(response?.data));
    }
  };

  useEffect(() => {
    fetchNotifications(userData?.user?.id);
  }, [page, page_size, search]);

  const handlePaginationLengthChange = (newPageSize) => {
    setPaginationLength(newPageSize);
    dispatch(setPageSize(newPageSize));
  };

  useEffect(() => {
    $.fn.dataTable.ext.errMode = "none";
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();

    const renderPagination = () => {
      const table = $("#SentNotificationListTable").DataTable();
      const totalPages = total_pages;
      const currentPage = page;

      const paginationContainer = $(".dataTables_paginate");
      const paginationList = $("<ul>", { class: "pagination" });
      paginationContainer.empty().append(paginationList);

      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);

      if (totalPages > 5 && currentPage <= 3) {
        endPage = 5;
      } else if (totalPages > 5 && currentPage >= totalPages - 2) {
        startPage = totalPages - 4;
      }
      const firstButton = $("<a>", {
        text: "<<",
        class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
        click: function () {
          if (currentPage > 1) {
            dispatch(setPage(1));
            table.page("first").draw(false);
          }
        },
      });

      const previousButton = $("<a>", {
        text: "<",
        class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
        click: function () {
          if (currentPage > 1) {
            dispatch(setPage(page - 1));
            table.page("previous").draw(false);
          }
        },
      });

      const nextButton = $("<a>", {
        text: ">",
        class: `page-link pointer ${
          currentPage === totalPages ? "disabled" : ""
        }`,
        click: function () {
          if (currentPage < totalPages) {
            dispatch(setPage(page + 1));
            table.page("next").draw(false);
          }
        },
      });
      const lastButton = $("<a>", {
        text: ">>",
        class: `page-link pointer ${
          currentPage === totalPages ? "disabled" : ""
        }`,
        click: function () {
          if (currentPage < totalPages) {
            dispatch(setPage(totalPages));
            table.page("last").draw(false);
          }
        },
      });

      const firstListItem = $("<li>", {
        class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
      }).append(firstButton);
      const previousListItem = $("<li>", {
        class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
      }).append(previousButton);
      const nextListItem = $("<li>", {
        class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
      }).append(nextButton);
      const lastListItem = $("<li>", {
        class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
      }).append(lastButton);
      paginationList.append(firstListItem);
      paginationList.append(previousListItem);

      for (let i = startPage; i <= endPage; i++) {
        const pageLink = $("<a>", {
          text: i,
          class: `page-link pointer ${i === currentPage ? "active" : ""}`,
          click: function () {
            dispatch(setPage(i));
            table.page(i - 1).draw(false);
          },
        });

        const listItem = $("<li>", {
          class: `page-item ${i === currentPage ? "active" : ""}`,
        }).append(pageLink);
        paginationList.append(listItem);
      }

      paginationList.append(nextListItem);
      paginationList.append(lastListItem);
    };

    const table = $("#SentNotificationListTable").DataTable({
      searching: true,
      order: [],
      data: sentNotificationsList || [],
      columns: [
        {
          title: "Sr",
          data: "id",
          width: 50,
          render: function (data, type, full, meta) {
            return meta.row + 1;
          },
        },
        {
          title: "Send To",
          data: "notifiable_type",
          width: 60,
          render: function (data, type, full, meta) {
            return data?.split("\\")?.pop() == "User"
              ? (full?.user?.title ? full?.user?.title : "") +
                  full?.user?.first_name +
                  " " +
                  full?.user?.last_name
              : data?.split("\\")?.pop() == "Staff"
              ? (full?.staff?.title ? full?.staff?.title : "") +
                full?.staff?.first_name +
                " " +
                full?.staff?.last_name
              : data?.split("\\")?.pop() == "Admin"
              ? (full?.admin?.title ? full?.admin?.title : "") +
                full?.admin?.first_name +
                " " +
                full?.admin?.last_name
              : "-";
          },
        },
        {
          title: "Name",
          data: "notifiable_type",
          width: 60,
          render: function (data, type, full, meta) {
            return data
              ? data?.split("\\")?.pop() == "User"
                ? "Employer"
                : data?.split("\\")?.pop() == "Staff"
                ? "Caregiver"
                : "Admin"
              : "-";
          },
        },
        {
          title: "Subject",
          data: "data",
          width: "auto",
          render: function (data, type, full, meta) {
            return data ? limitString(JSON.parse(data)?.subject, 15) : "-";
          },
        },

        {
          title: "Sent Via",
          data: "data",
          width: "auto",
          render: function (data, type, full, meta) {
            const parsedData = data ? JSON.parse(data) : "";

            return `
                            <div style="text-align: left; border: 1px solid #ccc; padding: 2px; display: grid; grid-template-columns: max-content auto;">
                                <strong style="border: 1px solid #ccc; padding: 2px;">Mail:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${
                                  parsedData?.mail === 1 ? "Yes" : "No"
                                }</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">SMS:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${
                                  parsedData?.sms === 1 ? "Yes" : "No"
                                }</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Portal:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${
                                  parsedData?.bc === 1 ? "Yes" : "No"
                                }</span>

                                <strong style="border: 1px solid #ccc; padding: 2px;">Pop-up:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${
                                  parsedData?.popup === 1 ? "Yes" : "No"
                                }</span>
                            </div>
                        `;
          },
        },
        // {
        //     title: "Content", width: 100, data: "data", "render": function (data, type, full, meta) {
        //         return data ;
        //     }
        // },
        {
          title: "Read Status",
          data: "read_at",
          width: 60,
          render: function (data, type, full, meta) {
            return data
              ? "<span class='text-success'>" +
                  moment(data).fromNow() +
                  "</span>"
              : "Unread";
          },
        },
        {
          title: "Time",
          data: "created_at",
          width: 60,
          render: function (data, type, full, meta) {
            return data ? moment(data).fromNow() : "Unread";
          },
        },
        {
          title: "Action",
          width: 60,
        },
      ],

      language: {
        search: "",
        searchPlaceholder: "Search",
        emptyTable: "No Record Found",
        info:
          "Showing " + from + " to " + to + " of " + totalRecords + " entries",
      },

      drawCallback: function () {
        renderPagination();
      },
    });

    table.page.len(paginationLength).draw();
    $("#SentNotificationListTable").on(
      "length.dt",
      function (e, settings, len) {
        handlePaginationLengthChange(len);
      }
    );

    $("#global-search").on("keyup", function () {
      dispatch(setSearch(this.value));
      table.search(this.value).draw();
    });

    $("#page-size").on("change", function () {
      const newSize = $(this).val();
      dispatch(setPageSize(parseInt(newSize)));
      table.page.len(parseInt(newSize)).draw();
    });

    return () => {
      $("#SentNotificationListTable").off("length.dt");
      table.destroy();
    };
  }, [sentNotificationsList, page, page_size, total_pages]);

  return (
    <AdminLayout>
      <Breadcrumb items={breadcrumbItems} />
      <div className="overview-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="configure-head mt-0 mb-4">
                <div className="same-heading-icon">
                  <h4>Sent Notifications List</h4>
                </div>
              </div>
              <div className="date-section">
                <div className="d-flex flex-wrap gap-3">
                  <div className="select-field">
                    <select
                      id="page-size"
                      defaultValue={page_size}
                      onChange={(e) => {
                        handlePaginationLengthChange(e.target.value);
                      }}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div className="search-field">
                    <div className="input-group">
                      <input
                        type="text"
                        id="global-search"
                        placeholder="Search..."
                      />
                      <div className="input-group-append">
                        <button className="btn btn-success" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    className="common-same-button"
                    type="button"
                    onClick={openAddNotificationModal}
                  >
                    Add New Notification
                  </button>
                </div>
              </div>
              <div className="white-box">
                <div className="common-table m-0">
                  <table
                    className="table dt-responsive table-hover nowrap table-striped list-table"
                    style={{ width: "100%" }}
                    id="SentNotificationListTable"
                  ></table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddNotificationModal && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <AddNotificationByAdminModal
            closeAddNotificationModal={closeAddNotificationModal}
            onNotificationAdded={() => {
              fetchNotifications(userData?.user?.id);
            }}
          />
        </div>
      )}

      <Spinner isLoading={isFetching || isLoading} />
    </AdminLayout>
  );
};

export default AdminNotificationManagement;
