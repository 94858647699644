import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  page_size: 25,
  search: "",
  notificationList: [],
  sentNotificationsList: [],
  total_pages: 0,
  totalRecords: 0,
  from: 0,
  to: 0,
  unreadCount: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationList: (state, action) => {
      console.log("action", action.payload);
      state.notificationList = action.payload.notifications;
      state.totalRecords = action.payload.total_record;
      state.total_pages = Math.ceil(
        action.payload.total_record / state.page_size
      );
      state.from = (state.page - 1) * state.page_size + 1;
      state.to = Math.min(
        state.page * state.page_size,
        action.payload.total_record
      );
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload.unread;
    },
    setSentNotificationsList: (state, action) => {
      state.sentNotificationsList = action.payload.notifications;
      state.totalRecords = action.payload.total_record;
      state.total_pages = Math.ceil(
        action.payload.total_record / state.page_size
      );
      state.from = (state.page - 1) * state.page_size + 1;
      state.to = Math.min(
        state.page * state.page_size,
        action.payload.total_record
      );
    },
    setPage: (state, action) => {
      state.page = action.payload;
      state.from = (state.page - 1) * state.page_size + 1;
      state.to = Math.min(state.page * state.page_size, state.totalRecords);
    },
    setPageSize: (state, action) => {
      state.page_size = action.payload;
      state.page = 1; // Reset to the first page
      state.from = 1;
      state.to = Math.min(state.page_size, state.totalRecords);
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const {
  setNotificationList,
  setSentNotificationsList,
  setPage,
  setPageSize,
  setSearch,
  setUnreadCount,
} = notificationSlice.actions;

export default notificationSlice.reducer;
